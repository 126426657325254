/* eslint-disable camelcase */
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useRouter } from "next/router";
import { TFunction } from "next-i18next";
import { Spin } from "antd";
import Document from "@public/assets/icons/document-upload.svg";
import { useIsWidgetToApply } from "@hooks";
import { EQueryAction, EWidgetEvents, EWidgetType } from "@enums";
import { WidgetRepository } from "@repositories";
import { loadSdk, loadStyle } from "@utils/candidate";
import { LoadingOutlined } from "@ant-design/icons";
import { JobsContext, LandingContext } from "@contextjobs.context";
import { setUrlState, signUpWithUtms } from "@utils/url";
import style from "./createAccountButton.module.scss";
import { EWidgetModes } from "@enums";
import { EXPIRED_JOB_UTMS } from "@constants/utms.constant";

type Props = {
  t: TFunction;
  propStyle?: string;
  name?: string;
  withIcon?: boolean;
  onClose?: React.Dispatch<React.SetStateAction<boolean>>;
  mode?: EWidgetModes;
  Icon?: string | null;
  suffixIcon?: React.ReactNode;
  isFromExpiredJob?: boolean;
};

export const CreateAccount: React.FC<Props> = ({
  t,
  propStyle,
  name = t<string>("jobOffers:offer.createAccount"),
  withIcon = true,
  Icon = Document,
  onClose,
  mode = EWidgetModes.default,
  suffixIcon,
  isFromExpiredJob = false
}) => {
  const jobContext = useContext(JobsContext);
  const landingContext = useContext(LandingContext);
  const [loading, setLoading] = useState<boolean>(false);
  const isWidgetIframe = useIsWidgetToApply(EWidgetType.iframe);
  const [sdkLoaded, setSdkLoaded] = useState<boolean>(false);
  const router = useRouter();
  const jobDetail = useMemo(
    () => ({
      isInternal: jobContext?.selectedJob?.isInternal
    }),
    [jobContext?.selectedJob?.isInternal]
  );

  const onClick = useCallback(
    (event: any) => {
      if (isWidgetIframe) return signUpWithUtms(router.query);
      event.preventDefault();
      onClose && onClose(false);

      if (isFromExpiredJob && jobContext?.selectedJob?.id) {
        const utms = {
          ...EXPIRED_JOB_UTMS,
          utm_content: "signup-button"
        };
        const url = new URL(window.location.href);
        for (const [key, value] of Object.entries(utms)) {
          url.searchParams.set(key, value);
        }
        router.push(url.toString());
      }
      const profileAppRoot = window.document.querySelector(".magneto-signup-v2-modal");
      setLoading(true);
      setSdkLoaded(false);
      if (mode === EWidgetModes.apply) {
        sessionStorage.setItem("jobDetailAction", EWidgetModes.apply);
        sessionStorage.setItem("hasQuestionnaire", String(jobContext?.selectedJob?.hasPrefilterQuestionnaire || false));
        if (jobContext?.selectedJob?.isInternal) {
          sessionStorage.setItem("jobDetail", JSON.stringify(jobDetail));
        }
      } else if (mode === EWidgetModes.save) {
        sessionStorage.setItem("jobDetailAction", EWidgetModes.save);
      }
      profileAppRoot?.setAttribute("data-modal-status", "open");
      profileAppRoot?.setAttribute("data-startmode", mode);
      sessionStorage.removeItem("jobId");
      if (jobContext?.selectedJob?.id) {
        sessionStorage.setItem("jobId", `${jobContext?.selectedJob?.id}`);
      }
      WidgetRepository.getSignUpScripts().then((response) =>
        loadStyle("magneto.ui.signup", response.payloadStyle).then(() =>
          loadSdk("magneto.sdk.signup", response.payloadScript).then(() => {
            setLoading(false);
            setTimeout(() => {
              setSdkLoaded(true);
            }, 500);
          })
        )
      );
      setUrlState({
        companySlug: landingContext.landingPage?.mainCompanySlug,
        jobSlug: jobContext.selectedJob?.jobSlug,
        action: jobContext?.jobDetailAction,
        route: router.route,
        t
      });
    },
    [
      jobContext?.selectedJob?.id,
      landingContext.landingPage?.mainCompanySlug,
      jobContext.selectedJob?.jobSlug,
      jobContext?.jobDetailAction,
      isWidgetIframe,
      router,
      onClose,
      t,
      jobContext?.selectedJob?.hasPrefilterQuestionnaire,
      mode,
      isFromExpiredJob,
      jobContext?.selectedJob?.isInternal,
      jobDetail
    ]
  );

  useEffect(() => {
    if (sdkLoaded && mode) {
      window.postMessage({ event: EWidgetEvents.changeMode, message: { mode } }, "*");
    }
  }, [sdkLoaded, mode]);

  useEffect(() => {
    const action = `${router.query.action || ""}`;
    const signUpButton: NodeListOf<Element> = window.document.querySelectorAll('body a[href="magneto://signup"]');
    signUpButton.forEach((item) => {
      item.addEventListener("click", onClick);
    });
    if (action === EQueryAction.signUp) {
      const url = new URL(window.location.href);
      url.searchParams.delete("action");
      window.history.replaceState({}, "", url.toString());
      onClick({ preventDefault: () => null });
    }
    return () => {
      signUpButton.forEach((item) => {
        item.removeEventListener("click", onClick);
      });
    };
  }, [onClick, router.query.action]);

  const validateWidgetIframe = useMemo(() => {
    return {
      btn: isWidgetIframe ? "btnIframe" : "sign-up-button",
      text: isWidgetIframe ? "textIframe" : "text-button"
    };
  }, [isWidgetIframe]);

  return (
    <React.Fragment>
      <button className={propStyle ? propStyle : style[validateWidgetIframe.btn]} onClick={onClick}>
        {loading && <Spin indicator={<LoadingOutlined className={style.spinner} spin />} />}
        {!loading && withIcon && <Icon width={24} height={24} loading="lazy" />}
        <span className={propStyle ? "" : style[validateWidgetIframe.text]}>{name}</span>
        {suffixIcon && <span>{suffixIcon}</span>}
      </button>
      <div className="magneto-signup-v2-modal" data-button-create="no" />
    </React.Fragment>
  );
};
